import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Loading } from 'components/shared';
import { fetchActivityIngredients } from 'redux/productionProgress/rth/actions';

import MenuNavigation from '../MenuNavigation';
import IngredientList from '../IngredientList';
import styles from './styles';

const useStyles = makeStyles(styles);

const ActivityIngredients = ({ activity }) => {
  const dispatch = useDispatch();
  const { menuId } = useParams();

  const classes = useStyles();

  const {
    activityIngredients,
    activityIngredientsFetching: fetching,
    activityIngredientsFailed: failed,
  } = useSelector((state) => state.rthProductionProgress);

  useEffect(() => {
    menuId &&
      dispatch(
        fetchActivityIngredients({ menuId: menuId, activity: activity })
      );
  }, [menuId]);

  return (
    <div className={classes.container}>
      <MenuNavigation />
      {failed && (
        <div className={classes.error}>Failed to fetch ingredients</div>
      )}
      {fetching && <Loading fullScreen />}
      {!failed && !fetching && (
        <IngredientList
          ingredients={activityIngredients}
          headerText="Compound Ingredient"
        />
      )}
    </div>
  );
};

ActivityIngredients.propTypes = {
  activity: PropTypes.string.isRequired,
};

export default ActivityIngredients;
